var config = BAMBUSER_PLAYER_CONFIG || {};
var node_env = NODE_ENV || ''; // eslint-disable-line no-undef

module.exports = {
  create: (target, resourceUri, opts) => {
    if (!(target instanceof HTMLElement)) {
      // Shift arguments
      opts = resourceUri;
      resourceUri = target;
    }
    if (typeof resourceUri !== 'string') throw new Error('Missing resourceUri');

    var defaultOpts = {
      audioDescriptionEnabled: false,
      captionsEnabled: false,
      noFullscreen: false,
      timeshift: false,
      usePreviewAsPoster: undefined, // let player decide defaults
      _experimentalTouchSurfaceLogic: false,
    };
    opts = opts || {};
    Object.keys(defaultOpts).forEach(key => {
      if (typeof opts[key] === 'undefined') opts[key] = defaultOpts[key];
    });

    var controller = new BambuserPlayerController(resourceUri, opts);
    if (target instanceof HTMLElement) {
      controller.setupPlayerIn(target);
    }
    return controller;
  }
};

class BambuserPlayerController {
  constructor(resourceUri, opts) {
    this._resourceUri = resourceUri;
    this._opts = opts;
    this._iframe = null;
    this._frameId = 'player-' + Math.round(Math.random() * 10000000);
    this._attributes = {};

    this._isReady = false;
    this._startWhenReady = false;
    this._loadWhenReady = false;
    this._playPromises = {};

    // Standard HTML5 video properties (exposed via getters/setters below)
    this._autoplay = undefined;
    this._buffered = [];
    this._controls = undefined;
    this._currentTime = undefined;
    this._duration = NaN;
    this._ended = false;
    this._error = null;
    this._muted = undefined;
    this._paused = true;
    this._poster = undefined;
    this._readyState = 0;
    this._seeking = false;
    this._videoHeight = 0;
    this._videoWidth = 0;
    this._volume = undefined;

    // Custom properties (exposed via getters/setters below)
    this._availableCaptions = [];
    this._broadcastId = undefined;
    this._broadcastStatus = undefined;
    this._captionsLanguageCode = undefined;
    this._contentPreset = '';
    this._isLive = false;
    this._playerType = '';
    this._scaleMode = undefined;
    this._timeshift = typeof opts.timeshift === 'undefined' ? undefined : opts.timeshift;
    this._viewers = {
      current: 0,
      total: 0
    };
    this._playerInstanceId = undefined;

    this._setupExternalInterface();
  }

  _emit(event) {
    if (this._listeners && this._listeners[event]) {
      this._listeners[event].forEach(listener => {
        listener(event);
      });
    }
  }

  _dispatchAction(action, value) {
    var message = {
      action: action,
      value: value
    };
    message = JSON.stringify(message);
    if (this._iframe) {
      this._iframe.contentWindow.window.postMessage(message, '*');
    }
  }

  _setupExternalInterface() {
    window.addEventListener('message', event => {
      if (event.origin !== config.hostedPlayer.host && node_env !== 'development') {
        // Don't trust messages from other origins
        return;
      }

      // On old IE versions the iframe will post messages as JSON
      // encoded strings. Decode before continuing.
      var data = event.data;
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (e) {
          return;
        }
      }

      var videoEvent = data.videoEvent;
      if (!videoEvent || videoEvent.frameId !== this._frameId) {
        // Only accept messages sent by this controller's iframe
        return;
      }

      if (videoEvent) {
        switch (videoEvent.name) {
          // Capture initial state
          case '__state__':
            this._duration = videoEvent.data.duration;
            this._ended = videoEvent.data.ended;
            this._paused = videoEvent.data.paused;
            this._readyState = videoEvent.data.readyState;
            this._seeking = videoEvent.data.seeking;

            this._availableCaptions = videoEvent.data.availableCaptions;
            this._broadcastId = videoEvent.data.broadcastId;
            this._broadcastStatus = videoEvent.data.broadcastStatus;
            this._contentPreset = videoEvent.data.contentPreset;
            this._isLive = videoEvent.data.isLive;
            this._playerType = videoEvent.data.playerType;
            this._playerInstanceId = videoEvent.data.playerInstanceId;

            if (!this._isReady) {
              this._emit('ready');
              this._isReady = true;

              // Use setters to configure properties which may have been
              // set before we had the initial state:
              // - if configured earlier: use the setter to set the corresponding
              // property in the host player
              // - if not configured earlier: update the property with the value
              // from the host player
              this.autoplay = isUndefined(this._autoplay) ? videoEvent.data.autoplay : this.autoplay;
              this.timeshift = isUndefined(this._timeshift) ? videoEvent.data.timeshift : this.timeshift;
              this.closedCaptionsLanguageCode = isUndefined(this._captionsLanguageCode) ? videoEvent.data.captionsLanguageCode : this.captionsLanguageCode;
              this.controls = isUndefined(this._controls) ? videoEvent.data.controls : this.controls;
              if (isUndefined(this._currentTime)) {
                if (videoEvent.data.currentOffsetFromStart > -1) {
                  this.currentTime = videoEvent.data.currentOffsetFromStart;
                } else {
                  this.currentTime = videoEvent.data.currentTime;
                }
              } else {
                this.currentTime = this.currentTime;
              }
              this.muted = isUndefined(this._muted) ? videoEvent.data.muted : this.muted;
              this.poster = isUndefined(this._poster) ? videoEvent.data.poster : this.poster;
              this.volume = isUndefined(this._volume) ? videoEvent.data.volume : this.volume;
              this.scaleMode = isUndefined(this._scaleMode) ? videoEvent.data.scaleMode : this.scaleMode;
              Object.keys(this._attributes).forEach(key => {
                this.setAttribute(key, this._attributes[key]);
              });
            }

            if (this._startWhenReady) {
              // A play() event was registered before the player was ready
              // Dispatch all pending play intents to the host
              Object.keys(this._playPromises).forEach(id => {
                this._dispatchAction('play', id);
              });
            }

            if (this._loadWhenReady) {
              // A load() event was registered before the player was ready
              this.load();
            }
            break;

          case '__video_dimensions__':
            if (videoEvent.data.videoHeight) this._videoHeight = videoEvent.data.videoHeight;
            if (videoEvent.data.videoWidth) this._videoWidth = videoEvent.data.videoWidth;
            break;

          case '__exception__':
            throw videoEvent.data;

          case '__play_promise_result__':
            // Resolve or reject the corresponding promise, created
            // in an earlier play() call
            const { id, resolved, error: err } = videoEvent.data;
            const promise = this._playPromises[id];
            if (promise) {
              if (resolved) {
                promise.resolve();
              } else {
                let syntheticError;
                if (err && err.name) {
                  syntheticError = new Error();
                  syntheticError.name = err.name;
                  syntheticError.message = err.message;
                }
                promise.reject(syntheticError);
              }
              delete this._playPromises[id];
            }
            break;

          // Capture video events
          case 'availablecaptionsupdate':
            this._availableCaptions = videoEvent.data;
            break;
          case 'ended':
            this._ended = true;
            break;
          case 'error':
            // Construct an instance of Error based on the information sent from the host
            const error = new Error(videoEvent.data.message);
            if (videoEvent.data.name) {
              error.name = videoEvent.data.name;
            }
            this._error = error;
            break;
          case 'durationchange':
            this._duration = videoEvent.data;
            break;
          case 'pause':
            this._paused = true;
            break;
          case 'play':
            this._paused = false;
            break;
          case 'playing':
            this._seeking = false;
            break;
          case 'progress':
            this._buffered = videoEvent.data;
            break;
          case 'seeked':
            this._seeking = false;
            break;
          case 'seeking':
            this._seeking = true;
            break;
          case 'timeupdate':
            if (videoEvent.data.currentOffsetFromStart > -1) {
              this._currentTime = videoEvent.data.currentOffsetFromStart;
            } else {
              this._currentTime = videoEvent.data.currentTime;
            }
            break;
          case 'viewers':
            this._viewers = videoEvent.data;
            break;
          case 'volumechange':
            this._volume = videoEvent.data.volume;
            this._muted = videoEvent.data.muted;
            break;

          case 'canplay':
          case 'loadedmetadata':
          case 'loadeddata':
          case 'suspend':
          case 'waiting':
            break;

          // Vendor specific events
          case 'webkitendfullscreen':
            break;

          default:
            break;
        }
        this._emit(videoEvent.name);
      }
    }, false);
  }


  /**
   * Public API (a subset of the standard HTML5 video element API)
   */

  addEventListener(event, callback) {
    if (typeof callback === 'function') {
      if (!this._listeners) this._listeners = {};
      if (!this._listeners[event]) this._listeners[event] = [];
      this._listeners[event].push(callback);
    }
  }

  removeEventListener(event, callback) {
    if (this._listeners && this._listeners[event]) {
      var index = this._listeners[event].indexOf(callback);
      if (index !== -1) {
        this._listeners[event].splice(index, 1);
      }
    }
  }

  setAttribute(key, value) {
    this._attributes[key] = value;
    this._dispatchAction('setAttribute', {key, value});
  }

  get buffered() {
    return {
      length: this._buffered.length,
      start: id => this._buffered[id][0],
      end: id => this._buffered[id][1]
    };
  }

  get autoplay() {
    if (typeof this._autoplay === 'undefined') return false;
    return this._autoplay;
  }

  get controls() {
    if (typeof this._controls === 'undefined') return false;
    return this._controls;
  }

  get currentTime() {
    if (typeof this._currentTime === 'undefined') return 0;
    return this._currentTime;
  }

  get ended() {
    return this._ended;
  }

  get error() {
    return this._error;
  }

  get duration() {
    return this._duration;
  }

  get muted() {
    if (typeof this._muted === 'undefined') return false;
    return this._muted;
  }

  get paused() {
    return this._paused;
  }

  get poster() {
    if (typeof this._poster === 'undefined') return '';
    return this._poster;
  }

  get readyState() {
    return this._readyState;
  }

  get seeking() {
    return this._seeking;
  }

  get videoHeight() {
    return this._videoHeight;
  }

  get videoWidth() {
    return this._videoWidth;
  }

  get viewers() {
    return this._viewers;
  }

  get volume() {
    if (typeof this._volume === 'undefined') return 1;
    return this._volume;
  }

  set autoplay(value) {
    this._autoplay = !!value;
    this._dispatchAction('setAutoplay', this._autoplay);
  }

  set controls(bool) {
    this._controls = !!bool;
    if (this._iframe && !this._opts._experimentalTouchSurfaceLogic) {
      // Prevent the iframe from capturing pointer events when player
      // controls are not desired. Let the iframe's container element
      // capture the events instead.
      // Conversely, when controls are enabled, make the iframe capture
      // pointer events, otherwise the user will not be able to touch
      // the controls.
      this._iframe.style.pointerEvents = this._controls ? '' : 'none';
    }
    this._dispatchAction('setControls', this._controls);
  }

  set currentTime(position) {
    this._currentTime = position;
    this._dispatchAction('setCurrentTime', position);
  }

  set muted(bool) {
    this._muted = !!bool;
    this._dispatchAction('setMuted', this._muted);
  }

  set poster(poster) {
    if (!poster) {
      poster = '';
    } else if (typeof poster === 'string') {
      if (!poster.match(/^http/) && !poster.match(/^\/\//)) {
        // This looks like a relative url, which would not work
        // inside the iframe. Prepend the input with the origin
        // of the current page.
        if (!poster.match(/^\//)) poster = '/' + poster;
        poster = window.location.origin + poster;
      }
    }
    this._poster = poster;
    this._dispatchAction('setPoster', poster);
  }

  set volume(volume) {
    this._volume = volume;
    this._dispatchAction('setVolume', volume);
  }

  pause() {
    this._dispatchAction('pause');
  }

  play(returnPromise = true) {
    this._startWhenReady = true;

    let promise, promiseId;
    if (returnPromise && window.Promise) {
      // Create a promise that we'll return to the caller.
      // We'll resolve or reject the promise based on the result
      // of the promise returned by play() in the host.
      promiseId = 'p' + Math.random();
      let resolver, rejecter;
      promise = new Promise((res, rej) => {
        resolver = res;
        rejecter = rej;
      });
      this._playPromises[promiseId] = {
        resolve: resolver,
        reject: rejecter,
      };
    }
    this._dispatchAction('play', promiseId);
    return returnPromise ? promise : undefined;
  }

  load() {
    if (!this._isReady) {
      this._loadWhenReady = true;
    }
    this._dispatchAction('load');
  }

  /**
   * Custom API
   */

  get availableCaptions() {
    return this._availableCaptions;
  }

  get broadcastId() {
    return this._broadcastId;
  }

  get broadcastStatus() {
    return this._broadcastStatus;
  }

  get captionsLanguageCode() {
    return this._captionsLanguageCode;
  }

  set captionsLanguageCode(code) {
    this._captionsLanguageCode = code;
    this._dispatchAction('setCaptionsLanguageCode', code);
  }

  get contentPreset() {
    return this._contentPreset;
  }

  get isLive() {
    return this._isLive;
  }

  get playerInstanceId() {
    return this._playerInstanceId;
  }

  get playerType() {
    return this._playerType;
  }

  get scaleMode() {
    return this._scaleMode || '';
  }

  set scaleMode(value) {
    this._scaleMode = value;
    this._dispatchAction('setScaleMode', value);
  }

  get timeshift() {
    return this._timeshift;
  }

  set timeshift(value) {
    this._timeshift = value;
    this._dispatchAction('setTimeshift', value);
  }

  getHTMLElement() {
    if (this._iframe) return this._iframe;

    var url = config.hostedPlayer.host + config.hostedPlayer.path;
    var query = {
      frameId: this._frameId,
      resourceUri: this._resourceUri,
      volume: this.volume,
      autoplay: this.autoplay,
      audioDescriptionEnabled: this._opts.audioDescriptionEnabled,
      captionsEnabled: this._opts.captionsEnabled,
      timeshift: this._opts.timeshift,
      usePreviewAsPoster: this._opts.usePreviewAsPoster,
      host: window.location.host,
      _experimentalTouchSurfaceLogic: this._opts._experimentalTouchSurfaceLogic,
    };
    if (this._opts.applicationId) {
      query.applicationId = this._opts.applicationId;
    }
    if (this._opts.allowedPlayerTypes) {
      query.allowedPlayerTypes = this._opts.allowedPlayerTypes.toString();
    }
    if (this._opts.allowedPresets) {
      query.allowedPresets = this._opts.allowedPresets.toString();
    }
    url += '?' + Object.keys(query).map(key => {
      return key + '=' + encodeURIComponent(query[key]);
    }).join('&');

    if (this._opts.startPosition || this._opts.endPosition) {
      var start = encodeURIComponent(this._opts.startPosition || '');
      var end = this._opts.endPosition ? ',' + encodeURIComponent(this._opts.endPosition) : '';
      url += '#t=' + start + end;
    }

    var iframe = this._iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 0;
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('scrolling', 'no');
    iframe.setAttribute('marginWidth', '0');
    iframe.setAttribute('marginHeight', '0');
    iframe.setAttribute('allow', 'autoplay' + (!this._opts.noFullscreen ? '; fullscreen' : ''));
    iframe.src = url;

    return iframe;
  }

  setupPlayerIn(target) {
    target.innerHTML = '';
    target.appendChild(this.getHTMLElement());
  }
}

function isUndefined(o) {
  return typeof o === 'undefined';
}
